* {
  box-sizing: border-box;
  margin: 0; /* remove browser defaults  */
  padding: 0; /* remove browser defaults */
}

html {
  font-family: 'Roboto', sans-serif;
}

body {
  background: #fafafa;
  height: 100vh;
  overscroll-behavior-y: none;
}
